import * as Sentry from '@sentry/nextjs'
import { AxiosResponse } from 'axios'
import firebase from 'firebase/app'
import HttpStatus from 'http-status-codes'

import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../data/protocols/http'
import { authCookies, env, sentryConfig } from '../../main/config'
import { makeStorageProvider } from '../../main/factories'
import { Utils } from '../../utils'
import axiosETAGCache from '../../utils/axiosEtagInterceptor'
import checkTokenExpired from '../../utils/checkTokenExpired'

Sentry.configureScope(scope => {
  scope.setTag(sentryConfig.transactionName, sentryConfig.transactionId)
})

const api = axiosETAGCache()

// add auth token if authenticated
api.interceptors.request.use(async config => {
  const isServer = typeof window === 'undefined'

  if (!env.isTest && !isServer) {
    const storageProvider = makeStorageProvider()

    const firebaseToken = storageProvider.get(authCookies.COOKIE_TOKEN_KEY)

    if (!checkTokenExpired(firebaseToken)) {
      firebase.auth?.().onIdTokenChanged(user => {
        if (user) {
          user.getIdToken?.().then(token => {
            storageProvider.set(authCookies.COOKIE_TOKEN_KEY, token)
            return token
          })
        }
      })
    }

    if (firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`
    }
  }

  // Sentry
  if (Utils.validator.isAzosDomain(config?.url || '')) {
    config.headers[sentryConfig.headerName] = sentryConfig.transactionId
  }

  return config
})

api.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config || {}

    if (
      (error?.response?.status === 412 || error?.response?.status === 428) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      try {
        const getEtag = await api.get(error.config.url)
        return api(originalRequest, {
          headers: { 'If-Match': getEtag.data._etag },
        })
      } catch (e) {
        return Promise.reject(error)
      }
    }

    if (
      error?.response?.status !== HttpStatus.UNAUTHORIZED &&
      error?.response?.status !== HttpStatus.FORBIDDEN
    ) {
      Sentry.setExtras(originalRequest)
      Sentry.captureException(error, { level: 'error' })
    }

    return Promise.reject(error)
  },
)

class AxiosHttpClient implements HttpClient {
  constructor(private readonly token: string) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      const headers = data.headers || {}

      if (this.token) {
        Object.assign(headers, { Authorization: `Bearer ${this.token}` })
      }

      axiosResponse = await api.request({
        baseURL: env.baseURL,
        url: data.url,
        method: data.method,
        data: data.body,
        headers,
      })
    } catch (error: any) {
      const isServer = typeof window === 'undefined'

      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !isServer
      ) {
        const storageProvider = makeStorageProvider()

        storageProvider.remove(authCookies.COOKIE_USER_KEY)
        storageProvider.remove(authCookies.COOKIE_TOKEN_KEY)

        window.location.replace(`/login?origin=${window.location.pathname}`)
      }

      axiosResponse =
        error?.response ||
        ({
          status: 500,
          data: undefined,
        } as AxiosResponse)
    }

    return {
      statusCode: axiosResponse.status,
      data: axiosResponse.data,
    }
  }
}

export class AxiosHttpClientFactory {
  private static instance: AxiosHttpClient | null = null

  static factory(token?: string): AxiosHttpClient {
    if (!!token) {
      this.instance = new AxiosHttpClient(token)
    } else if (this.instance === null) {
      this.instance = new AxiosHttpClient('')
    }
    return this.instance
  }
}
