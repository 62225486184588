import React from 'react'

import { ParsedUrlQuery } from 'querystring'

import { makeAuthService, makeTokenService } from '../main/factories/services'

type useDashboardTokenProps = {
  qs: ParsedUrlQuery
}

const tokenService = makeTokenService()
const authService = makeAuthService()

const useDashboardToken = ({ qs }: useDashboardTokenProps) => {
  const getToken = React.useCallback(async () => {
    const token = String(qs?.token ?? '')

    if (token) {
      tokenService.removeToken()
      const { session } = await tokenService.saveToken(token)
      if (session) await authService.getUserBySessionToken(session, false)
    }
  }, [qs])

  React.useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qs])
}

export { useDashboardToken }
