export const formatToISODate = (date: Date | string): string => {
  const formatted = date.toString().replace(/\s/, 'T').replace(/(T.*)/, '')
  const [year, month, day] = formatted.split('-')
  const data = new Date(+year, +month - 1, +day, 3, 0, 0)
  return data.toISOString()
}

export const formatDate = (date: Date): string => {
  return date.toISOString()
}

export const formatResetDate = (date: Date): string => {
  return formatDate(resetTime(date))
}

export const formatOnlyDate = (date: Date): string => {
  return resetTime(date).toISOString().split('T')[0]
}

export function resetTime(date: Date): Date {
  const data = new Date(date)
  data.setUTCHours(0, 0, 0, 0)
  return data
}

export const formatDateAndTime = (date: string): string => {
  const formattedDate = new Intl.DateTimeFormat('pt-BR', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date))
  return formattedDate
}

export const formatExtensionDate = (date: string): string => {
  const formattedDate = new Intl.DateTimeFormat('pt-BR', {
    month: 'long',
    day: 'numeric',
  }).format(new Date(date))
  return formattedDate
}

export const delayedDays = (date: string): number => {
  const startDate = new Date(date)
  const endDate: Date = new Date()

  const timeInMilisec: number = endDate.getTime() - startDate.getTime()
  const daysBetweenDates: number = Math.ceil(
    timeInMilisec / (1000 * 60 * 60 * 24),
  )

  return daysBetweenDates
}
