import { Dispatcher } from '../data/protocols/dispatcher'
import { env } from '../main/config/env'
declare const window: any

/**
 * DataLayer
 *
 * @category Infra
 * @typedef Dispatcher
 */
export class DataLayerDispatcher implements Dispatcher {
  static _instance: Dispatcher

  constructor() {
    if (typeof window !== 'undefined' && !Array.isArray(window.dataLayer)) {
      window.dataLayer = []
    }
  }

  static getInstance(): Dispatcher {
    if (!this._instance) {
      this._instance = new DataLayerDispatcher()
    }
    return this._instance
  }

  execute(name: string, attributes?: Record<string, unknown>): void {
    try {
      const trackData = Object.assign(
        { event: name },
        attributes ? { eventProps: attributes } : {},
      )

      window.dataLayer.push(trackData)
      if (!env.isProduction) {
        console.group('analytics')
        console.info('event name:', trackData.event)
        if (trackData.eventProps) {
          console.info('event data:', trackData.eventProps || {})
          console.table(Object.entries(trackData.eventProps || {}))
        }
        console.groupEnd()
      }
    } catch {
      // ignore track event errors
    }
  }
}
