import { HttpClient, UnexpectedError } from '@azos/core'
import { LoadProfessionsUseCase } from 'domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IProfessionsRepository = LoadProfessionsUseCase

export class ProfessionsRepository implements IProfessionsRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async load(): Promise<LoadProfessionsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/professions`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadProfessionsUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
