import React from 'react'

import { useToken } from '@azos/account'
import { useUTM } from '@azos/core'
import { useWidget } from '@presentation/hooks/useWidget'
import { useRouter } from 'next/router'

import { Root } from './MainContainer.styles'

const MainContainer: React.FCC = ({ children }) => {
  const { query } = useRouter()

  useUTM({ qs: query })
  useWidget({ qs: query })
  useToken({ qs: query })

  return <Root>{children}</Root>
}

export default MainContainer
