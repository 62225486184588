import React from 'react'

import {
  makeLoadQuotationByIdService,
  makeUpdateInsuredUserService,
} from '@azos/core'
import Router from 'next/router'

import { Registration } from '../../components'
import { Container } from '../../containers'
import { useAuth } from '../../hooks'

type RegisterProps = {
  onSuccessful?: () => Promise<void>
  onError?: () => void
}

const RegisterView: React.VFC<RegisterProps> = ({ onSuccessful, onError }) => {
  const { from } = useAuth()

  const handleSuccess = React.useCallback(async () => {
    await onSuccessful?.()
    await Router.replace(from)
  }, [from, onSuccessful])

  return (
    <>
      <Container>
        <Registration
          loadQuotationbyIdService={makeLoadQuotationByIdService()}
          updateInsuredUserService={makeUpdateInsuredUserService()}
          onSuccessful={handleSuccess}
          onError={onError}
        />
      </Container>
    </>
  )
}

export default RegisterView
