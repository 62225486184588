import React from 'react'

import analytics from '@azos/analytics'
import { makeLoadUserProfileService } from '@azos/core'

import { makeAuthService } from '../../main/factories/services'
import {
  AuthProvider,
  AuthProviderCallbacks,
  AuthSuccessfulData,
} from '../../providers'

type AppProviderProps = AuthProviderCallbacks & {}

const AppProvider: React.FCC<AppProviderProps> = ({
  children,
  onSuccessful,
  onError,
  onInfo,
}) => {
  const handleSuccessful = async (data: AuthSuccessfulData) => {
    analytics.account.login.execute({
      firebase_id: data.user.uid,
      email: data.user.email,
    })
    await onSuccessful?.(data)
  }

  return (
    <AuthProvider
      authService={makeAuthService()}
      loadTypeUserService={makeLoadUserProfileService()}
      onSuccessful={handleSuccessful}
      onError={onError}
      onInfo={onInfo}
    >
      {children}
    </AuthProvider>
  )
}

export default AppProvider
