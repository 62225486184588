import { CoveragesCode } from './coverages'

export enum QuotationSaleChannel {
  B2C = 'b2c',
  B2B = 'b2b',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export type WidgetQuotation = {
  birth_date: Date | string
  height: number
  weight: number
  gender: Gender
  is_smoker: boolean
  price: string
  widgetOrigin: string
}

export type Quotation = {
  _id: string
  //
  birth_date: Date | string
  height: number
  weight: number
  gender: Gender
  is_smoker: boolean
  sale_channel: QuotationSaleChannel
  full_name?: string
  social_name?: string | null
  //
  proposal_id: string
  //
  coverages: {
    code: CoveragesCode
    coverage_chosen: number
  }[]
  estimated_premiums: {
    code: CoveragesCode
    premium: number
  }[]
  //
  _etag: string
  _updated: string
  //
  profession_id?: string
  profession_description?: string
  salary?: number
}
