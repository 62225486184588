import { LoadQuotationByIdUseCase } from '../../../../domain/usecases'
import { LoadQuotationByIdMapper } from '../../../mappers'
import { SWRServices } from '../../../protocols/service'

export type ILoadQuotationByIdService = SWRServices<
  string,
  LoadQuotationByIdUseCase.Response
>

export class LoadQuotationByIdService implements ILoadQuotationByIdService {
  constructor(
    private readonly quotationsRepository: LoadQuotationByIdUseCase,
    private readonly loadQuotationByIdMapper: LoadQuotationByIdMapper,
  ) {}

  getUrl(quotationId: string): string {
    return `/quotation/${quotationId}`
  }

  async execute(
    quotationId: string,
  ): Promise<LoadQuotationByIdUseCase.Response> {
    const response = await this.quotationsRepository.loadById(quotationId)

    return this.loadQuotationByIdMapper.map(response)
  }
}
