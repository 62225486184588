import React from 'react'

import { authCookies, makeStorageProvider } from '@azos/core'
import Router from 'next/router'

import { Utils } from '../utils'

type ProvateRouteOptions = Partial<{
  loginUrl: string
  origin?: string
}>

const storageProvider = makeStorageProvider()

export function withPrivateRoute(
  WrappedComponent: any,
  options?: ProvateRouteOptions,
) {
  const config: Required<ProvateRouteOptions> = Object.assign(
    {
      loginUrl: '/login',
      origin: '',
    },
    options,
  )

  const hocComponent = ({ ...props }) => <WrappedComponent {...props} />
  hocComponent.getLayout = WrappedComponent.getLayout

  hocComponent.getInitialProps = async (context: any) => {
    let token = null

    if (config.origin) {
      const url = context.req.url
      const qs = Utils.queryString.formatQueryStringUrl(url)
      config.origin = `${config.origin}${qs}`
    }

    const loginURL = Utils.formatter.formatLoginUrl(
      config.loginUrl,
      config.origin || context.pathname,
    )

    if (context.res) {
      token = context.req.cookies[authCookies.COOKIE_TOKEN_KEY]

      if (!token) {
        context.res.writeHead(302, { Location: loginURL })
        context.res.end()
        return
      }
    } else {
      token = storageProvider.get(authCookies.COOKIE_TOKEN_KEY)

      if (!token) {
        Router.replace(loginURL)
      } else {
        storageProvider.set(authCookies.COOKIE_TOKEN_KEY, token)
      }
    }

    if (WrappedComponent.getInitialProps) {
      const wrappedProps = await WrappedComponent.getInitialProps({
        ...context,
        token,
      })
      return { ...wrappedProps }
    }

    return {}
  }

  return hocComponent
}
