import React from 'react'

import { useAuth, useUserConfirmation } from '@azos/account'
import { Avatar, Button, Link, Modal, useMediaQuery } from '@azos/shared'
import { makeNotifyAbandonedCartService } from '@main/factories/services'
import { Box, Typography } from '@mui/material'
import Router from 'next/router'

import { Profile, Root } from './UserConfirmation.styles'

const notifyAbandonedCartService = makeNotifyAbandonedCartService()

const UserConfirmation: React.FCC = ({ children, ...rest }) => {
  const { user, logout } = useAuth()
  const { isMobile } = useMediaQuery()
  const { isConfirmattedUser, confirmUser } = useUserConfirmation()

  const [open, setOpen] = React.useState<boolean>(!isConfirmattedUser)

  const handleConfirmUser = React.useCallback(async () => {
    confirmUser()
    setOpen(false)
  }, [confirmUser])

  const handleChangeUser = React.useCallback(async () => {
    confirmUser(false)
    setOpen(false)
    await logout()
    Router.replace('/cadastro')
  }, [confirmUser, logout])

  React.useEffect(() => {
    if (isConfirmattedUser) {
      notifyAbandonedCartService
        .execute()
        .catch(() => console.error('Erro ao notificar carrinho abandonado!'))
    }
  }, [isConfirmattedUser])

  return (
    <React.Fragment>
      <Modal
        open={open}
        variant={isMobile ? 'bottomDrawer' : 'modal'}
        showHeader={false}
        showCloseButton={false}
      >
        <Root>
          <Typography className="title" variant="h6" paragraph>
            Olá <strong>{user?.displayName}</strong>, deseja continuar com o seu
            usuário?
          </Typography>

          <Profile>
            <Avatar name={user?.displayName || ''} photoUrl={user?.photoURL} />
            <Box className="avatar__content">
              <Typography
                variant="h6"
                component="span"
                title={user?.displayName}
              >
                {user?.displayName}
              </Typography>
              <Typography component="span" title={user?.email} paragraph>
                {user?.email}
              </Typography>
              <Link href="#" title="Trocar conta" onClick={handleChangeUser}>
                Trocar conta
              </Link>
            </Box>
          </Profile>

          <Button
            className="confirmButton"
            variant="contained"
            title="Continuar"
            size="large"
            onClick={handleConfirmUser}
            fullWidth
          >
            Continuar
          </Button>
        </Root>
      </Modal>

      {children}
    </React.Fragment>
  )
}

export default UserConfirmation
