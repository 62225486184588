import { Gender } from '../../../domain/models'
import { UpdateInsuredUser } from '../../../domain/usecases'
import { Utils } from '../../../utils'
import { Mapper } from '../../protocols'

type Request = {
  email: string
  full_name: string
  social_name?: string | null
  birth_date: Date
  gender: Gender
  phone: string
  authorizes_whatsapp_contact: boolean
  cpf: string
  marital_status: string
  is_politically_exposed_person: boolean
  //
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
  number: number
  complement: string
}
type Response = UpdateInsuredUser.Params

export class UpdateInsuredUserMapper implements Mapper {
  map(params: Request): Response {
    return {
      ...params,
      full_name: Utils.sanitizer.capitalize(params.full_name),
      birth_date: Utils.date.formatResetDate(params.birth_date),
      phone: Utils.sanitizer.onlyNumber(params.phone),
      cpf: Utils.sanitizer.onlyNumber(params.cpf),
      cep: Utils.sanitizer.onlyNumber(params.cep),
      valid: undefined,
    }
  }

  mapCollection(response: Request[]): Response[] {
    return response.map(this.map.bind(this))
  }
}
