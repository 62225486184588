import { HttpClient } from '@azos/core'

import {
  LoadUserProfileService,
  UpdateInsuredUserService,
} from '../../../data/services'
import { makeUpdateInsuredUserMapper } from '../../../main'
import { makeInsuredUserRepository, makeUsersRepository } from '../repositories'

export const makeLoadUserProfileService = (httpClient?: HttpClient) =>
  new LoadUserProfileService(makeUsersRepository(httpClient))

export const makeUpdateInsuredUserService = () =>
  new UpdateInsuredUserService(
    makeInsuredUserRepository(),
    makeUpdateInsuredUserMapper(),
  )
