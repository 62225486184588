import HttpStatusCode from 'http-status-codes'

import { HttpClient } from '../../../data/protocols'
import { UnexpectedError } from '../../../domain/errors'
import { CreateBeneficiaries, LoadKinships } from '../../../domain/usecases'

export type IBeneficiariesRepository = LoadKinships

export class BeneficiariesRepository implements IBeneficiariesRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async createBeneficiaries(
    params: CreateBeneficiaries.Params,
  ): Promise<CreateBeneficiaries.Response> {
    const { proposal_id, ...payload } = params

    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposal_id}/beneficiaries`,
      method: 'post',
      body: payload,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return undefined
      default:
        throw new UnexpectedError()
    }
  }

  async loadKinships(): Promise<LoadKinships.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/beneficiaries/kinship`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadKinships.Response
      default:
        throw new UnexpectedError()
    }
  }
}
