import {
  CheckoutPaymentFrequency,
  CheckoutServiceSource,
  Creditcard,
  ICheckBillingStatusService,
  ICoreCheckoutRepository,
  ILoadInvoiceDataService,
  IProposalConsumer,
  IProposalProducer,
  PaymentError,
  PaymentInvoiceLastTransactionStatus,
  PaymentInvoicesStatus,
  PaymentInvoiceStatusResponse,
  PaymentMethod,
  PaymentUserError,
  Services,
} from '@azos/core'
import { ICreateContractService } from '@data/services'

type Request = {
  paymentMethod: PaymentMethod
  creditcard?: Creditcard
  checkoutServiceSource?: CheckoutServiceSource
  expiresAt?: string
  paymentFrequency: CheckoutPaymentFrequency
  discountConfigurationId?: string
  priceWithDiscount?: number
}
type Response = {
  isPaid: boolean
  invoice?: PaymentInvoiceStatusResponse
}

export type ICreatePaymentService = Services<Request, Response>

type PaymentVerified = {
  isPaid: boolean
  invoice?: PaymentInvoiceStatusResponse
}

export class CreatePaymentService implements ICreatePaymentService {
  constructor(
    private readonly createContractService: ICreateContractService,
    private readonly checkBillingStatusService: ICheckBillingStatusService,
    private readonly checkoutCoreRepository: ICoreCheckoutRepository,
    private readonly loadInvoiceDataService: ILoadInvoiceDataService,
    private readonly proposalConsumer: IProposalConsumer,
    private readonly proposalProducer: IProposalProducer,
  ) {}

  async execute({
    paymentMethod,
    creditcard,
    checkoutServiceSource,
    expiresAt,
    paymentFrequency,
    discountConfigurationId,
    priceWithDiscount,
  }: Request): Promise<Response> {
    try {
      const { proposalId } = this.proposalConsumer.execute()

      if (!proposalId) throw new PaymentUserError()

      const contract = await this.createContractService.execute({
        proposalId,
        paymentMethod,
        creditcard,
        checkoutServiceSource,
        expiresAt,
        paymentFrequency,
        discountConfigurationId,
        priceWithDiscount,
      })

      if (contract.isPaid) {
        return {
          isPaid: contract.isPaid,
          invoice: undefined,
        }
      }

      await this.checkBillingStatusService.execute(proposalId)

      const { invoice, isPaid } = await this.loadInvoiceData(
        proposalId,
        paymentMethod,
      )

      if (!isPaid && !invoice) {
        throw new PaymentError('Não conseguimos gerar dados de pagamento')
      }

      return { isPaid, invoice }
    } catch (error) {
      if (error instanceof PaymentUserError) {
        this.proposalProducer.execute(undefined)
      }
      throw error
    }
  }

  private async loadInvoiceData(
    proposalId: string,
    paymentMethod: PaymentMethod,
  ): Promise<PaymentVerified> {
    const invoices = await this.checkoutCoreRepository.loadInvoices({
      proposalId,
    })

    const isPaid = invoices.some(this.checkIsPaid)

    if (isPaid) {
      return {
        isPaid,
        invoice: undefined,
      }
    }

    const invoice = await this.loadInvoiceDataService.execute({
      proposalId,
      paymentMethod,
    })

    const isInvoicePaid = this.checkIsInvoicePaid(invoice)

    if (isInvoicePaid) {
      return {
        isPaid: true,
        invoice,
      }
    }

    return { isPaid, invoice }
  }

  private checkIsPaid(invoice: PaymentInvoiceStatusResponse): boolean {
    switch (invoice.status) {
      case PaymentInvoicesStatus.paid:
        return true
      default:
        return false
    }
  }

  private checkIsInvoicePaid(invoice: PaymentInvoiceStatusResponse): boolean {
    if (
      !invoice ||
      !invoice?.charges ||
      invoice?.charges.length === 0 ||
      !invoice?.charges[0]?.last_transaction
    )
      return false

    switch (invoice.charges[0].last_transaction.status) {
      case PaymentInvoiceLastTransactionStatus.success:
        return true
      default:
        return false
    }
  }

  dispose(): void {
    this.createContractService.dispose?.()
    this.checkBillingStatusService.dispose?.()
    this.loadInvoiceDataService.dispose?.()
  }
}
