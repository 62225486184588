import { Mapper } from '../../../data/protocols'
import { LoadQuotationByIdUseCase } from '../../../domain/usecases'
import { Utils } from '../../../utils'

type Request = LoadQuotationByIdUseCase.Response
type Response = LoadQuotationByIdUseCase.Response

type ILoadQuotationByIdMapper = Mapper<Request, Response>

export class LoadQuotationByIdMapper implements ILoadQuotationByIdMapper {
  map(response: Request): Response {
    return {
      ...response,
      birth_date: Utils.date.formatToISODate(response.birth_date),
    }
  }

  mapCollection(response: Request[]): Response[] {
    return response.map(this.map.bind(this))
  }
}
