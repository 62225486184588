import { makeAxiosHttpClient } from '@azos/core'
import { DPSRepository } from '@data/repositories'
import { CreateDPSService, GetDPSQuestionnaireService } from '@data/services'
import { env } from '@main/config/env'

import { makeDPSRepository } from '../repositories'

export const makeGetDPSQuestionnaireService = () =>
  new GetDPSQuestionnaireService(makeDPSRepository())

export const makeCreateDPSService = () =>
  new CreateDPSService(makeDPSRepository())

export const createDPSQuestionnaire = params =>
  new DPSRepository(
    env.BFF_BASE_URL,
    makeAxiosHttpClient(),
  ).createDPSQuestionnaire(params)

export const generateDPS3Score = params =>
  new DPSRepository(env.BFF_BASE_URL, makeAxiosHttpClient()).generateDPS3Score(
    params,
  )
