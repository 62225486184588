import {
  PaymentBillingTimeoutError,
  PaymentError,
  PaymentTimeoutError,
} from '../../../../domain/errors'
import { PaymentBillingStatus } from '../../../../domain/models'
import { LoadBillingStatus } from '../../../../domain/usecases'
import { IDelayService, IRetryService, Services } from '../../../protocols'

export type ICheckBillingStatusService = Services<string, void>

export class CheckBillingStatusService implements ICheckBillingStatusService {
  constructor(
    private readonly retryService: IRetryService,
    private readonly delayService: IDelayService,
    private readonly loadBillingStatusRepository: LoadBillingStatus,
  ) {}

  async execute(proposalId: string): Promise<void> {
    const now = () => new Date(Date.now())

    return new Promise((resolve, reject) => {
      const service = () =>
        this.loadBillingStatusRepository.loadBillingStatus(proposalId)

      const retryRequest = (time: Date) => {
        // update interval time
        const interval = now().getTime() - time.getTime()
        // delay
        this.delayService
          .delay(this.delayService.duration - interval)
          .then(() => {
            // retry
            executePolling()
          })
      }

      const executePolling = async () => {
        const startTime = now()

        return this.retryService
          .retry(service)
          .then(({ billing_status }: LoadBillingStatus.Response) => {
            if (this.checkIsFinished(billing_status)) {
              return resolve()
            }
            throw new Error()
          })
          .catch(error => {
            if (error instanceof PaymentTimeoutError) {
              return reject(new PaymentBillingTimeoutError())
            }
            if (error instanceof PaymentError) {
              return reject(error)
            }
            // retry
            retryRequest(startTime)
          })
      }

      // execute
      executePolling()
    })
  }

  private checkIsFinished(status: PaymentBillingStatus) {
    return status === PaymentBillingStatus.finished
  }

  dispose() {
    this.delayService.destroy()
  }
}
