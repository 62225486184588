import dayjs from 'dayjs'
import jwt_decode from 'jwt-decode'

const checkTokenExpired = (token: string): boolean => {
  if (token) {
    const tokenExp = jwt_decode<{ exp: string }>(token).exp
    return dayjs.unix(parseInt(tokenExp, 10)).isBefore(dayjs())
  }
  return false
}

export default checkTokenExpired
