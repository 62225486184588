import NodeCache from 'node-cache'

export interface CacheValue {
  etag: string
  value: unknown
}

export class Cache {
  static instance: Cache

  cache: NodeCache

  static getInstance(): Cache {
    if (!this.instance) {
      this.instance = new Cache()
    }
    return this.instance
  }

  static get(uuid: string): CacheValue | undefined {
    return this.getInstance().cache.get(uuid)
  }

  static set(uuid: string, etag: string, value: unknown): boolean {
    return this.getInstance().cache.set(uuid, { etag, value })
  }

  static reset(): void {
    this.getInstance().cache.flushAll()
  }

  constructor() {
    this.cache = new NodeCache()
  }
}
