import { IInsuredUserRepository } from '../../../../data/repositories'
import { InsuredUser, UpdateInsuredUser } from '../../../../domain'

export class FakeInsuredUserRepository implements IInsuredUserRepository {
  params: any
  output: any

  async loadInsuredUser(): Promise<InsuredUser> {
    return this.output
  }

  async updateInsuredUser(
    params: UpdateInsuredUser.Params,
  ): Promise<UpdateInsuredUser.Response> {
    this.params = params
    return this.output
  }
}
