import {
  Gender,
  ILoadQuotationByIdService,
  IUpdateInsuredUserService,
} from '@azos/core'

export type RegistrationValue = {
  full_name: string
  social_name?: string | null
  birth_date: Date | null
  gender: Gender
  phone: string
  authorizes_whatsapp_contact: boolean
  cpf: string
  marital_status: string
  is_politically_exposed_person: string | null
  //
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
  number: string
  complement: string
}

export type RegistrationProps = {
  loadQuotationbyIdService: ILoadQuotationByIdService
  updateInsuredUserService: IUpdateInsuredUserService
  //
  onSuccessful?: () => Promise<void>
  onError?: () => void
}

export const INITIAL_VALUES: RegistrationValue = {
  full_name: '',
  social_name: null,
  birth_date: null,
  gender: Gender.MALE,
  phone: '',
  authorizes_whatsapp_contact: false,
  cpf: '',
  marital_status: '',
  is_politically_exposed_person: 'false',
  //
  cep: '',
  state: '',
  city: '',
  street: '',
  neighborhood: '',
  number: '',
  complement: '',
}

// export const INITIAL_VALUES: RegistrationValue = {
//   full_name: '',
//   birth_date: null,
//   gender: Gender.MALE,
//   phone: '(11) 21312-3123',
//   authorizes_whatsapp_contact: false,
//   cpf: '619.886.670-03',
//   marital_status: 'married',
//   is_politically_exposed_person: 'false',
//   //
//   cep: '09850-300',
//   state: '',
//   city: '',
//   street: '',
//   neighborhood: '',
//   number: '123',
//   complement: '',
// }
