import mixpanel from 'mixpanel-browser'

import { Dispatcher } from '../data/protocols/dispatcher'
import { User } from '../domain/models'
import { env } from '../main/config/env'

/**
 * MixpanelDispatcher
 *
 * @category Infra
 * @typedef Dispatcher
 */
export class MixpanelDispatcher implements Dispatcher {
  static _instance: Dispatcher

  constructor() {}

  static async initialize(id: string, userData: User): Promise<Dispatcher> {
    if (!env.isProduction) {
      console.log('Mixpanel initialized!')
      return this._instance
    }

    mixpanel.init(id, {
      debug: !env.isProduction,
      ignore_dnt: true,
    })

    if (userData.email) {
      await mixpanel.identify(userData.email)
      mixpanel.people.set('$email', userData.email)

      if (userData.displayName) {
        mixpanel.people.set('$name', userData.displayName)
      }

      mixpanel.register({
        email: userData.email,
        isInsured: userData.isInsured,
        isInsuredByBroker: userData.isInsuredByBroker,
        isBroker: userData.isBroker,
        ...(userData.brokerUser?.fullName
          ? { insuredBy: userData.brokerUser.fullName }
          : {}),
      })
    }

    return this._instance
  }

  static getInstance(): Dispatcher {
    if (!this._instance) {
      this._instance = new MixpanelDispatcher()
    }

    return this._instance
  }

  execute(name: string, attributes?: Record<string, unknown>): void {
    try {
      if (!env.isProduction) {
        console.group('mixpanel')
        console.info('event name:', name)
        if (attributes) {
          console.info('event data:', attributes || {})
          console.table(Object.entries(attributes || {}))
        }
        console.groupEnd()
        return
      }

      mixpanel.track(name, attributes)
    } catch {
      // ignore track event errors
    }
  }

  static logout(): void {
    try {
      mixpanel?.reset()
    } catch {
      // ignore track event errors
    }
  }
}
