import { authCookies, Storage } from '@azos/core'

import cloudClient from '../infra/cloud'

const COOKIE_SESSION_KEY = '__session'

export type CookieData = {
  proposalId?: string
  quotationId?: string
}

type SessionData = {
  token: string
  session: string | undefined
}

export interface ITokenService {
  getToken(): string
  getSessionToken(): string
  saveToken(token: string): Promise<SessionData>
  removeToken(): void
}

export class TokenService implements ITokenService {
  constructor(private readonly storage: Storage) {}

  getToken(): string {
    return this.storage.get(authCookies.COOKIE_TOKEN_KEY)
  }

  setToken(token: string): string {
    this.storage.set(authCookies.COOKIE_TOKEN_KEY, token)
    return token
  }

  getSessionToken(): string {
    return this.storage.get(COOKIE_SESSION_KEY)
  }

  async saveToken(token: string): Promise<SessionData> {
    let session: string | undefined = undefined

    await cloudClient
      .post<{ cookie: string }>('/auth/login', {
        token,
      })
      .then(({ data: { cookie } }) => {
        session = cookie
        this.storage.set(COOKIE_SESSION_KEY, cookie)
      })
      .catch(() => {
        this.removeToken()
      })

    return {
      token: this.setToken(token),
      session,
    }
  }

  removeSessionToken(): void {
    this.storage.remove(COOKIE_SESSION_KEY)
  }

  removeToken(): void {
    this.removeSessionToken()
    this.storage.remove(authCookies.COOKIE_TOKEN_KEY)
  }
}
