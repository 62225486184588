import { LoadKinships } from '../../../../domain/usecases'
import { SWRServices } from '../../../protocols/service'

export type ILoadKinshipsService = SWRServices<void, LoadKinships.Response>

export class LoadKinshipsService implements ILoadKinshipsService {
  constructor(private readonly beneficiariesRepository: LoadKinships) {}

  public getUrl() {
    return `/beneficiaries/kinship`
  }

  async execute(): Promise<LoadKinships.Response> {
    const response = await this.beneficiariesRepository.loadKinships()

    return response
  }
}
