import React from 'react'

import analytics from '@azos/analytics'
import { firebase } from '@azos/core'
import { Loading } from '@azos/shared'

import { User } from '../../domain/models'
import { AuthContextData, AuthProviderProps } from './AuthProvider.props'

export const AuthContext = React.createContext<AuthContextData>(
  {} as AuthContextData,
)

const AuthProvider: React.FCC<AuthProviderProps> = ({
  children,
  //
  authService,
  loadTypeUserService,
  //
  onSuccessful,
  onError,
  onInfo,
  onSignOut,
}) => {
  const isServer = typeof window === 'undefined'

  const origin = React.useRef<string>('')

  const [loading, setLoading] = React.useState<boolean>(true)
  const [registered, setRegistered] = React.useState<boolean>(false)
  const [user, setUser] = React.useState<User | null>(null)
  const [from, setFrom] = React.useState<string>('')

  const isSigned = React.useMemo<boolean>(() => !!user, [user])
  const isAllow = React.useMemo<boolean>(
    () => isSigned && registered && !loading,
    [isSigned, registered, loading],
  )

  React.useEffect(() => {
    if (!!from) {
      authService.setRedirectUrl(from)
      origin.current = from
    }
  }, [from, authService])

  React.useEffect(() => {
    if (!!user) authService.setUser(user)
    else authService.removeUser()
  }, [user, authService])

  const logout = React.useCallback(async () => {
    if (!isServer) {
      analytics.logout()
      await authService.signOut()
      setUser(null)
      window.localStorage.removeItem('@azos/showModalHomeWarning')
    }
  }, [authService, isServer])

  const signIn = React.useCallback(
    async (firebaseUser?: firebase.User | null): Promise<void> => {
      setLoading(true)

      try {
        const session = await authService.signIn(firebaseUser)

        if (!!session) {
          const { insuredUser, brokerUser, types } =
            await loadTypeUserService.execute()

          setRegistered(!!types.isInsured)

          const sessionUser: User = {
            ...session.user,
            insuredUser,
            brokerUser: types.broker,
            isInsured: types.isInsured,
            isBroker: types.isBroker,
            isInsuredByBroker: types.isInsuredByBroker,
          }

          setUser(sessionUser)

          await onSuccessful?.({
            from: origin.current,
            user: sessionUser,
            token: session.token,
          })
        } else {
          logout()
          onSignOut?.()
        }
      } catch (error) {
        onError?.()
      }

      setLoading(false)
    },
    [
      authService,
      loadTypeUserService,
      logout,
      onError,
      onSignOut,
      onSuccessful,
    ],
  )

  React.useEffect(() => {
    const data = authService.getSession()

    if (!!data) {
      if (data.user) setUser(data.user)
      if (data.redirectUrl) setFrom(data.redirectUrl)
    }

    const subscribe = firebase.auth().onIdTokenChanged(signIn)

    return subscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider
      value={{
        from,
        setFrom,
        //
        isLoading: loading,
        isSigned,
        isAllow,
        //
        user,
        setUser,
        logout,
        //
        isRegistered: registered,
        registered,
        setRegistered,
        //
        onError,
        onInfo,
      }}
    >
      {loading && <Loading />}
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
