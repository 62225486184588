import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .test({
      name: 'name',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'Seu nome e sobrenome é obrigatório',
    })
    .required('Seu nome é obrigatório'),
  socialName: Yup.string().when('hasSocialName', {
    is: true,
    then: Yup.string()
      .test({
        name: 'socialName',
        test: value => {
          const splitValue = value?.trim().split(' ')
          return (splitValue && splitValue?.length >= 2) || false
        },
        message: 'Seu nome e sobrenome é obrigatório',
      })
      .required('Seu nome social é obrigatório'),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Seu e-mail é obrigatório'),
  password: Yup.string()
    .min(8, 'Sua senha deve possuir no mínimo 8 caracteres')
    .required('Sua senha é obrigatória'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
    .required('A confirmação de senha é obrigatória'),
})
