import React from 'react'

import analytics from '@azos/analytics'
import { AppError, firebase, useProposalCookie } from '@azos/core'
import { Spinner } from '@azos/shared'

import { useAuth, useUserConfirmation } from '../../../hooks'
import { Wizard, WizardItem } from '../../molecules'
import { Wrapper } from './containers'
import {
  INITIAL_VALUES,
  RegistrationProps,
  RegistrationValue,
} from './Registration.props'
import { Box } from './Registration.styles'
import {
  Address,
  addressSchemaValidation,
  PersonalData,
  personalDataSchemaValidation,
} from './steps'

const Registration: React.VFC<RegistrationProps> = ({
  loadQuotationbyIdService,
  updateInsuredUserService,
  //
  onSuccessful,
  onError,
}) => {
  const { proposalCookie } = useProposalCookie()
  const { confirmUser } = useUserConfirmation()
  const {
    user,
    isLoading,
    onError: authOnError,
    setRegistered,
    isRegistered,
  } = useAuth()

  const initilized = React.useRef<boolean>(false)
  const disableFromQuotation = React.useRef<boolean>(false)

  const [isFullNameFieldDisabled, setIsFullNameFieldDisabled] =
    React.useState(false)

  const [initialValues, setInitialValues] =
    React.useState<RegistrationValue | null>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    if (!!user && !initilized.current) {
      const fullName =
        window.localStorage.getItem('@azos/userDisplayName') || ''
      const socialName =
        window.localStorage.getItem('@azos/userSocialName') || ''

      initilized.current = true
      loadQuotationbyIdService
        .execute(proposalCookie?.quotationId)
        .then(quotation => {
          const { birth_date, gender } = quotation

          const data = Object.assign({}, INITIAL_VALUES, {
            full_name: fullName,
            social_name: socialName?.trim() || null,
            birth_date,
            gender,
          } as Partial<RegistrationValue>)

          if (data.full_name) {
            setIsFullNameFieldDisabled(true)
          }

          disableFromQuotation.current = true
          setInitialValues(data)
        })
        .catch(() => {
          const data = Object.assign({}, INITIAL_VALUES, {
            full_name: fullName,
            social_name: socialName,
          })
          setInitialValues(data)
        })
    }
  }, [
    authOnError,
    isLoading,
    loadQuotationbyIdService,
    onError,
    proposalCookie?.quotationId,
    user,
  ])

  const handleSubmit = React.useCallback(
    async (value: Required<RegistrationValue>) => {
      setIsSubmitting(true)

      try {
        if (!user) throw new Error()

        const { email } = user
        const { birth_date, is_politically_exposed_person, number } = value

        const { full_name: displayName } =
          await updateInsuredUserService.execute({
            ...value,
            email: email.toLowerCase().normalize('NFD').trim(),
            birth_date: birth_date as Date,
            is_politically_exposed_person:
              is_politically_exposed_person === 'true',
            number: parseInt(number, 10),
          })

        if (displayName || value?.social_name) {
          await firebase
            .auth()
            .currentUser?.updateProfile({
              displayName: value?.social_name || displayName,
            })
            .catch(() => null)
        }

        analytics.account.registration.execute({
          firebase_id: user.uid,
          email: user.email,
        })

        confirmUser()
        setRegistered(true)
      } catch (e) {
        const errorMessage =
          e instanceof AppError
            ? e.message
            : 'Houve um problema ao criar o usuário'
        authOnError?.(errorMessage)
        onError?.()
        setIsSubmitting(false)
      }
    },
    [
      authOnError,
      confirmUser,
      onError,
      setRegistered,
      updateInsuredUserService,
      user,
    ],
  )

  React.useEffect(() => {
    if (isRegistered) {
      onSuccessful?.()
    }
  }, [isRegistered, onSuccessful])

  if (!initialValues) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return (
    <Wizard
      config={{
        initialSchema: personalDataSchemaValidation,
        initialValues,
        onSubmit: handleSubmit,
      }}
      wrapper={Wrapper}
    >
      <WizardItem
        component={() => (
          <PersonalData
            disabledUserField={disableFromQuotation.current}
            disableNameField={isFullNameFieldDisabled}
          />
        )}
        config={{
          schema: personalDataSchemaValidation,
          disableControls: true,
        }}
      />
      <WizardItem
        component={Address}
        config={{
          schema: addressSchemaValidation,
          disableControls: true,
          isSubmittingForm: isSubmitting,
        }}
      />
    </Wizard>
  )
}

export default Registration
