import HttpStatusCode from 'http-status-codes'

import {
  UnexpectedError,
  UnprocessableEntityError,
} from '../../../domain/errors'
import { UpdateInsuredUser } from '../../../domain/usecases'
import { HttpClient } from '../../protocols/http'

export type IInsuredUserRepository = UpdateInsuredUser

export class InsuredUserRepository implements IInsuredUserRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async updateInsuredUser(
    params: UpdateInsuredUser.Params,
  ): Promise<UpdateInsuredUser.Response> {
    const httpResponse = await this.httpClient.request({
      url: '/users/insured',
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as UpdateInsuredUser.Response
      case HttpStatusCode.UNPROCESSABLE_ENTITY:
        throw new UnprocessableEntityError()
      default:
        throw new UnexpectedError()
    }
  }
}
