import { HttpClient, UnexpectedError } from '@azos/core'
import { NotifyAbandonedCartUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type INotificationsRepository = NotifyAbandonedCartUseCase

export class NotificationsRepository implements INotificationsRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async notifyAbandonedCart(
    params: NotifyAbandonedCartUseCase.Params,
  ): Promise<NotifyAbandonedCartUseCase.Response> {
    const { proposalId, utm } = params

    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/notifications/proposal/${proposalId}/insured`,
      method: 'post',
      body: { utm },
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as NotifyAbandonedCartUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
