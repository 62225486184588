import React from 'react'

import { AuthContextData, AuthContext } from '../providers'

const useAuth = (): AuthContextData => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { useAuth }
