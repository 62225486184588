import React from 'react'

import { ParsedUrlQuery } from 'querystring'

import { makeTokenService } from '../main/factories/services'

type useTokenProps = {
  qs: ParsedUrlQuery
}

const authService = makeTokenService()

const useToken = ({ qs }: useTokenProps) => {
  const getToken = React.useCallback(async () => {
    const token = String(qs?.token ?? '')

    if (token) {
      authService.removeToken()
      await authService.saveToken(token)
    }
  }, [qs])

  React.useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useToken }
