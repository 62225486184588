export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  PIX = 'pix',
  BANK_SLIP = 'bank_slip',
}

export enum PaymentBillingStatus {
  pending = 'pending',
  processing = 'processing',
  error = 'error',
  finished = 'finished',
}

export enum PaymentInvoicesStatus {
  pending = 'pending',
  paid = 'paid',
  expired = 'expired',
  canceled = 'canceled',
  partially_paid = 'partially_paid',
  refunded = 'refunded',
  in_protest = 'in_protest',
  chargeback = 'chargeback',
  manual_refunded = 'manual_refunded',
  in_analysis = 'in_analysis',
}

export enum PaymentInvoiceLastTransactionStatus {
  success = 'success',
  error = 'error',
  pending = 'pending',
}

export type PaymentParams = {
  paymentMethod: PaymentMethod
  price: number
  paymentToken?: string
}

export type PaymentCreditCardParams = {
  first_name: string
  last_name: string
  number: string
  month: string
  year: string
  cvv: string
}

export type PaymentInvoiceStatusCharge = {
  id: string
  status: string
  due_at: string
  last_transaction: {
    id: number
    transaction_type: string
    status: string
    amount: string
    installments: number
    gateway_message: string
    gateway_response_code: null | string
    gateway_authorization: string
    gateway_transaction_id: string
    gateway_response_fields: {
      nsu: string
    }
    fraud_detector_score: null | string
    fraud_detector_status: null | string
    fraud_detector_id: null | string
    created_at: string
    gateway: {
      id: number
      connector: string
    }
    payment_profile: {
      id: number
      holder_name: string
      registry_code: null | string
      bank_branch: null | string
      bank_account: null | string
      card_expiration: string
      allow_as_fallback: null | string
      card_number_first_six: string
      card_number_last_four: string
      renewed_card: {
        card_number_last_four: null | string
        card_expiration: null | string
      }
      card_renewed_at: null | string
      token: string
      created_at: string
      payment_company: {
        id: number
        name: string
        code: string
      }
    }
  }
  payment_method: {
    id: number
    public_name: string
    name: string
    code: string
    type: string
  }
}

export type PaymentInvoiceStatusResponse = {
  payment_method: PaymentMethod
  due_date: string
  url: string
  value: number
  status: PaymentInvoicesStatus
  payment_data: Partial<{
    pix: {
      qrcode: string
      qrcode_text: string
    }
    bank_slip: {
      barcode: string
      digitable_line: string
    }
  }>
  charges: PaymentInvoiceStatusCharge[]
}

export type Creditcard = {
  ccname: string
  ccnumber: string
  cvv: string
  exp_date: string
}

export type CreditcardData = {
  number: string
  month: string
  year: string
  first_name: string
  last_name: string
  cvv: string
}
