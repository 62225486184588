import HttpStatusCode from 'http-status-codes'

import { UnexpectedError } from '../../../domain/errors'
import { LoadQuotationByIdUseCase } from '../../../domain/usecases'
import { HttpClient } from '../../protocols/http'

export type IQuotationsRepository = LoadQuotationByIdUseCase

export class QuotationsRepository implements IQuotationsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadById(
    quotationId: string,
  ): Promise<LoadQuotationByIdUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/quotation/${quotationId}`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadQuotationByIdUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
