import { Mapper } from '../../../../data/protocols'
import { AppError, UnprocessableEntityError } from '../../../../domain/errors'
import { Gender } from '../../../../domain/models'
import { UpdateInsuredUser } from '../../../../domain/usecases'
import { Services } from '../../../protocols/'

type Request = {
  email: string
  full_name: string
  social_name?: string | null
  birth_date: Date
  gender: Gender
  phone: string
  authorizes_whatsapp_contact: boolean
  cpf: string
  marital_status: string
  is_politically_exposed_person: boolean
  //
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
  number: number
  complement: string
  //
  valid?: unknown
}
type Response = UpdateInsuredUser.Response & {
  full_name: string
}

export type IUpdateInsuredUserService = Services<Request, Response>

export class UpdateInsuredUserService implements IUpdateInsuredUserService {
  constructor(
    private readonly insuredUserRepository: UpdateInsuredUser,
    private readonly mapper: Mapper,
  ) {}

  async execute(params: Request): Promise<Response> {
    try {
      const payload = this.mapper.map(params)

      const response = await this.insuredUserRepository.updateInsuredUser(
        payload,
      )

      return {
        ...response,
        full_name: payload.full_name,
      }
    } catch (e: any) {
      const errorMessage =
        e instanceof UnprocessableEntityError
          ? 'Este CPF já possui uma conta com outro email. Por favor, faça login.'
          : 'Houve um problema ao atualizar o usuário'

      throw new AppError(errorMessage)
    }
  }
}
