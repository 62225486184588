import HttpStatusCodes from 'http-status-codes'

import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http'

export class FakeHttpClient<R = any> implements HttpClient<R> {
  public url?: string

  public method?: string

  public body?: any

  public headers?: any

  public response: HttpResponse<R> = {
    statusCode: HttpStatusCodes.OK,
  }

  async request(data: HttpRequest): Promise<HttpResponse<R>> {
    this.url = data.url
    this.method = data.method
    this.body = data.body
    this.headers = data.headers
    return this.response
  }
}
