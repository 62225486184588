import { Cookies } from 'react-cookie'

import {
  Storage,
  StorageGetProps,
  StorageOptions,
  StorageSetProps,
} from '../../data/protocols'
import { env, storageConfig } from '../../main/config'

export class CookiesStorage implements Storage {
  private readonly cookies: Cookies

  constructor(
    readonly params = {},
    private readonly options: StorageOptions = storageConfig,
  ) {
    this.cookies = new Cookies(params)
  }

  set(key: string, value: any, options?: StorageSetProps): void {
    this.remove(key)
    this.cookies.set(key, value, { ...options, ...this.config })
  }

  get<T = any>(key: string, options?: StorageGetProps): T {
    return this.cookies.get<T>(key, options)
  }

  getJSON<T = any>(key: string, options?: StorageGetProps): T {
    const data = this.get<T>(key, options)
    return data || ({} as T)
  }

  remove(key: string, options?: StorageSetProps): void {
    this.cookies.remove(key, options)
    this.cookies.remove(key, { ...options, ...this.config })
  }

  private get domain(): string {
    return window.location.host.includes('.com.br') ? 'azos.com.br' : 'azos.dev'
  }

  private get config(): any {
    if (this.options.crossDomain && env.isProduction) {
      return {
        domain: this.domain,
        secure: true,
        sameSite: 'strict',
        path: '/',
      }
    }
    return {
      path: '/',
    }
  }
}
