const byLowerCase = (toFind: string) => (value: string) =>
  toLowerCase(value) === toFind
const toLowerCase = (value: string) => value.toLowerCase()
const getKeys = (headers: Record<string, unknown>) => Object.keys(headers)

export const getHeaderCaseInsensitive = (
  headerName: string,
  headers: Record<string, string> = {},
): string => {
  const header = getKeys(headers).find(byLowerCase(headerName))
  if (header) {
    return headers[header]
  }
  return ''
}
