import HttpStatusCode from 'http-status-codes'

import { UnexpectedError } from '../../../domain/errors'
import { HttpClient } from '../../protocols/http'

export type SetScorePayload = {
  sessionId: string
  proposalId: string
  user: any
}

export type IClearsaleRepository = {
  setScore(payload: SetScorePayload): Promise<void>
}

export class ClearsaleRepository implements IClearsaleRepository {
  constructor(
    private readonly baseUrl: string,
    private readonly httpClient: HttpClient,
  ) {}

  async setScore(payload: SetScorePayload): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/clearsale`,
      method: 'post',
      body: payload,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return
      default:
        throw new UnexpectedError()
    }
  }
}
