import React from 'react'

import { Box, BoxProps, Typography } from '@Azos-Seguros/apollo'
import { firebase, PRIVACY_POLICY_LINK, SERVICE_TERMS_LINK } from '@azos/core'
import { Tooltip } from '@azos/shared'
import { Button, Input, Checkbox } from '@azos/shared/src/components/v2'
import { Form, Formik, FormikValues } from 'formik'
import Link from 'next/link'

import { useAuth } from '../../../hooks'
import { initialValues } from './EmailRegister.props'
import { Root } from './EmailRegister.styles'
import { validationSchema } from './EmailRegister.validation'

type EmailRegisterProps = BoxProps

const EmailRegister: React.VFC<EmailRegisterProps> = ({ ...rest }) => {
  const { onError } = useAuth()
  const [hasSocialName, setHasSocialName] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (values: FormikValues) => {
      try {
        if (!hasSocialName) delete values.socialName

        const authProvider = firebase.auth()
        await authProvider.createUserWithEmailAndPassword(
          values.email.toLowerCase().normalize('NFD').trim(),
          values.password,
        )
        await authProvider.currentUser?.updateProfile({
          displayName: values?.socialName || values.name,
        })

        window.localStorage.setItem('@azos/userDisplayName', values.name)

        if (hasSocialName && values.socialName) {
          window.localStorage.setItem('@azos/userSocialName', values.socialName)
        }
      } catch (e: any) {
        const message = () => {
          switch (e.code) {
            case 'auth/invalid-email':
              return 'O e-mail informado é inválido'
            case 'auth/weak-password':
              return 'A senha informada é muito fraca, por favor, utilize uma senha mais forte'
            case 'auth/email-already-in-use':
              return 'Este e-mail já possui uma conta. Por favor, faça login'
            default:
              return 'Houve um problema ao autenticar'
          }
        }
        onError?.(message())
      }
    },
    [onError, hasSocialName],
  )

  return (
    <Root {...rest}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form>
            <Input
              name="name"
              label="Nome completo"
              placeholder="Seu nome completo"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
              helperText={touched.name ? errors.name : undefined}
              disabled={isSubmitting}
              className="email-register__input"
              fullWidth
            />

            <div className="tooltipWrapper">
              <Checkbox
                name="hasSocialName"
                label="Possui nome social?"
                checked={hasSocialName}
                onChange={() => setHasSocialName(!hasSocialName)}
              />

              <Tooltip
                className="tooltipContent"
                placement="top-start"
                tooltipContent="Nome usado por pessoas que se identificam com um gênero diferente do sexo biológico, em vez do registrado no nascimento."
              >
                <i className="icon-circle-help tooltip" />
              </Tooltip>
            </div>

            {hasSocialName && (
              <Input
                name="socialName"
                label="Nome social"
                placeholder="Seu nome social"
                value={values.socialName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && !!errors.name}
                helperText={touched.name ? errors.name : undefined}
                disabled={isSubmitting}
                className="email-register__input"
                fullWidth
              />
            )}

            <Input
              name="email"
              type="email"
              label="E-mail"
              placeholder="Seu e-mail pessoal"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={touched.email ? errors.email : undefined}
              disabled={isSubmitting}
              fullWidth
              className="email-register__input"
            />
            <Input
              name="password"
              type="password"
              label="Senha"
              placeholder="Sua senha"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && !!errors.password}
              helperText={touched.password ? errors.password : undefined}
              disabled={isSubmitting}
              fullWidth
              className="email-register__input"
            />
            <Input
              name="confirmPassword"
              type="password"
              label="Repetir senha"
              placeholder="Confirme sua senha"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && !!errors.confirmPassword}
              helperText={
                touched.confirmPassword ? errors.confirmPassword : undefined
              }
              disabled={isSubmitting}
              fullWidth
              className="email-register__input"
            />

            <Box className="email-register__text-content">
              <Typography variant="body3">
                Ao criar uma conta, você concorda com os{' '}
              </Typography>
              <Link
                href={SERVICE_TERMS_LINK}
                title="Termos de Serviços"
                passHref
              >
                <a
                  target="_blank"
                  className="email-register__text-content--link"
                >
                  Termos de Serviços
                </a>
              </Link>{' '}
              <Typography variant="body3">e </Typography>
              <Link
                href={PRIVACY_POLICY_LINK}
                title="Termos de Serviços"
                passHref
              >
                <a
                  target="_blank"
                  className="email-register__text-content--link"
                >
                  Política de Privacidade
                </a>
              </Link>
              .
            </Box>

            <Button type="submit" disabled={isSubmitting} fullWidth>
              Continuar
            </Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export default EmailRegister
