import { authCookies, firebase, Storage } from '@azos/core'

import {
  AuthUser,
  GetSession,
  SetSession,
  TokenResponse,
  User,
} from '../domain/models'
import cloudClient from '../infra/cloud'
import { ITokenService } from './token-service'

export interface IAuthService {
  signIn(user?: firebase.User | null): Promise<GetSession>
  signOut(): Promise<void>
  //
  getSession(): GetSession
  getFirebaseToken(): string | null
  //
  setSession(session: SetSession): GetSession
  setUser(user: User): void
  setRedirectUrl(redirectUrl: string): void
  //
  getUserBySessionToken(
    token: string,
    shouldSaveToken: boolean | undefined,
  ): Promise<GetSession | null>
  removeUser(): void
}

export class AuthService implements IAuthService {
  constructor(
    private readonly tokenService: ITokenService,
    private readonly storage: Storage,
  ) {}

  async signIn(user?: firebase.User | null): Promise<GetSession> {
    const sessionToken = this.tokenService.getSessionToken()

    if (!!user) {
      return this.saveUser(user)
    } else if (!!sessionToken) {
      return await this.getUserBySessionToken(sessionToken).catch(() => null)
    } else {
      return null
    }
  }

  async signOut(): Promise<void> {
    this.removeUser()
    this.tokenService.removeToken()
    await firebase
      .auth()
      .signOut()
      .catch(() => console.error('Error firebase signOut!'))
  }

  public removeUser() {
    this.storage.remove(authCookies.COOKIE_USER_KEY)
  }

  public setRedirectUrl(redirectUrl: string) {
    this.storage.set(authCookies.COOKIE_REDIRECT_FROM_KEY, redirectUrl)
  }

  public setSession({ user, token }: SetSession): GetSession {
    this.storage.set(authCookies.COOKIE_USER_KEY, this.parseUser(user))
    this.storage.set(authCookies.COOKIE_TOKEN_KEY, token)
    return this.getSession()
  }

  public getSession(): GetSession {
    const user = this.storage.getJSON(authCookies.COOKIE_USER_KEY)
    const token = this.storage.get(authCookies.COOKIE_TOKEN_KEY)
    const redirectUrl = this.storage.get(authCookies.COOKIE_REDIRECT_FROM_KEY)

    if (!user || !token) return null

    return {
      user,
      token,
      redirectUrl,
    }
  }

  public setUser(user: AuthUser) {
    this.storage.set(authCookies.COOKIE_USER_KEY, user)
  }

  getFirebaseToken(): string | null {
    return this.storage.get(authCookies.COOKIE_TOKEN_KEY) || null
  }

  private async saveUser(
    user: firebase.User,
    shouldSaveToken = true,
  ): Promise<GetSession> {
    const token = await user?.getIdToken()
    if (shouldSaveToken) this.tokenService.saveToken(token)
    const session = this.setSession({ user, token })
    return session
  }

  public async getUserBySessionToken(
    token: string,
    shouldSaveToken = true,
  ): Promise<GetSession | null> {
    const tokenResponse = await cloudClient
      .post<TokenResponse>('/auth/status', {
        session: token,
      })
      .catch(() => {
        throw new Error('Falha ao atualizar token do usuário')
      })

    return await firebase
      .auth()
      .signInWithCustomToken(tokenResponse.data.token)
      .then(async ({ user }) => {
        return !!user ? this.saveUser(user, shouldSaveToken) : null
      })
  }

  private parseUser(user: firebase.User): AuthUser {
    const { uid, displayName, photoURL, email } = user

    return {
      uid,
      displayName: displayName || '',
      email: email || '',
      photoURL: photoURL || undefined,
    }
  }
}
