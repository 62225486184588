import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

import { getHeaderCaseInsensitive } from './axiosEtagInterceptorUtils'
import { Cache } from './Cache'

function isCacheableMethod(config: AxiosRequestConfig) {
  const method = config.method?.toUpperCase()
  return method === 'GET' || method === 'HEAD'
}

function isEtagInterceptorMethod(config: AxiosRequestConfig) {
  const method = config.method?.toUpperCase()
  return method === 'PATCH' || method === 'PUT'
}

function getUUIDByAxiosConfig(config: AxiosRequestConfig) {
  return config.url || ''
}

function getCacheByAxiosConfig(config: AxiosRequestConfig) {
  return Cache.get(getUUIDByAxiosConfig(config))
}

function requestInterceptor(config: AxiosRequestConfig) {
  if (isCacheableMethod(config)) {
    const uuid = getUUIDByAxiosConfig(config)
    const lastCachedResult = Cache.get(uuid)
    if (lastCachedResult) {
      Object.assign(config.headers, {
        'If-None-Match': lastCachedResult.etag,
      })
    }
  }
  return config
}

function responseInterceptor(response: AxiosResponse) {
  if (isCacheableMethod(response.config)) {
    const responseETAG = getHeaderCaseInsensitive('etag', response.headers)
    if (responseETAG) {
      Cache.set(
        getUUIDByAxiosConfig(response.config),
        responseETAG,
        response.data,
      )
    }
  }
  return response
}

function responseErrorInterceptor(error: AxiosError) {
  if (error.response && error.response.status === 304) {
    const getCachedResult = getCacheByAxiosConfig(error.response.config)
    if (!getCachedResult) {
      return Promise.reject(error)
    }
    const newResponse = error.response
    newResponse.status = 200
    newResponse.data = getCachedResult.value
    return Promise.resolve(newResponse)
  }
  return Promise.reject(error)
}

async function etagInterceptor(config: AxiosRequestConfig) {
  if (isEtagInterceptorMethod(config)) {
    const uuid = getUUIDByAxiosConfig(config)
    const value = Cache.get(uuid)
    if (value?.etag) {
      const etag = value.etag.split('"')[1]
      Object.assign(config.headers, { 'If-Match': etag })
    }
  }
  return config
}

export function resetCache(): void {
  Cache.reset()
}

export default function axiosETAGCache(
  config?: AxiosRequestConfig,
): AxiosInstance {
  const instance = axios.create(config)
  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.request.use(etagInterceptor)
  instance.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor,
  )

  return instance
}
